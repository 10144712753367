@import '../../styles/mixins.scss';

.nav-link {
  @include roboto-medium;
  color: $white !important;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;

  &:hover {
    cursor: pointer;
  }

  @include xs-to-sm {
    color: $white !important;
  }
}

.navbar {
  padding: 0.5rem 1rem;

  .ml-auto {
    margin-left: auto !important;
  }
}

.navbar img {
  margin-top: 11px;
  margin-left: 86px;

  @include xs-to-sm {
    margin-left: 10px;
  }
}

.navbar-toggler-icon {
  background-image: url('../../assets/hamburger.png') !important;
  width: 34px !important;
  height: 26px !important;
}

.navbar-collapse {
  margin-right: 40px;
}

.navbar-toggler.collapsed {
  border: 0 !important;
}

.navbar-collapse.collapse.show {
  background-color: $dark;
  text-align: center;
  height: 100vh;
  padding-top: 60px;
  margin-left: -20px;
  margin-top: 45px;
  margin-right: -20px;
  & a {
    margin-bottom: 45px;
    font-size: 18px;
  }
}
